import React from "react"

import Logo from '../components/Logo/index'
import Headline from '../components/Headline/index'
import WhatGroup from '../components/WhatGroup/index'
import Layout from "../components/Layout/index" 
import Seo from "../components/Seo/index"
import '../assets/stylesheets/application.css';

const IndexPage = () => (
  <Layout>
    <Logo />
    <Headline />
    <WhatGroup />
    <Seo title="Sealbucket" />
  </Layout>
)

export default IndexPage
