import React from 'react';
import './styles.scss'

const What = ({ header, messageAlign, backColor, children }) => {
  const bc = backColor;
  let tc = '#fff';

  if (backColor === '#1c1c1c')
    tc = '#ffffff';

  if (backColor === '#ffffff') 
    tc = '#1c1c1c';

  return (
    <div className="what" align={messageAlign} style={{ backgroundColor:bc, color:tc }}>
      <p>
        { header }
      </p>
      { children }
    </div>
  );
};

export default What;
