import React from 'react'
import './styles.scss'

// import e22mblem_transparent from 'assets/images/SealbucketEmblemTransparent@2x.png';
import emblem_transparent from '../../assets/images/SealbucketEmblemTransparent@2x.png';

const Logo = () => {
    return (
      <div className="logo">
        <img src={emblem_transparent} alt="Sealbucket Logo Emblem"/>
      </div>
    )
}

export default Logo
