import React from 'react'
import What from '../What/index'
import { useMediaQuery } from 'react-responsive'
import './styles.scss'

const headerWhatWeAre = "What we are";
const headerWhatWeAreNot = "What we are not";

const WhatGroup = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' });
  var mesAlignRight = "right";
  var mesAlignLeft = "left";

  if (isMobile) {
    mesAlignRight = "center";
    mesAlignLeft = "center";
  }

  return (
    <div className="what-group">
      <What header={headerWhatWeAre} messageAlign={mesAlignRight} backColor="#1c1c1c">
        We <b>keep your software licenses</b> in a safe place in the cloud and let you <b>manage them whenever you like.</b> <br /><br />
        We work with specific software companies and <b>we serve only their licenses</b> in our service.
      </What>
      <What header={headerWhatWeAreNot} messageAlign={mesAlignLeft} backColor="#ffffff">
        We are <b>not a software asset manager.</b> It's <b>not possible for you to load</b> your software licenses and manage in our service.  <br /><br />
        We are <b>not a support service</b> for the software licenses you have. If you have any issues with the software you use, please <b>contact their vendors or where you bought the licenses.</b> 
      </What>
    </div>
  )
}

export default WhatGroup
