import React from 'react'
import "./styles.scss"

import logoTextTransparent from '../../assets/images/LogoTextTransparent.png';

const Headline = () => {
    return (
      <>
        <img className="head-img" src={logoTextTransparent} alt="Sealbucket Text"/>
        <h3>
          The cloud storage service for specified software licenses
        </h3>
      </>
    )
}

export default Headline
